import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";
import Information from "../components/Willach/Sections/Information";
import Photos from "../components/Willach/Sections/Photos";

const WillachPage = () => (
  <Layout>
    <SEO title="Willach" />
    <Hero title="Willach" />
    <Information />
    <Photos />
  </Layout>
)

export default WillachPage
