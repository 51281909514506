import React from "react"

import styled from 'styled-components';

import FirstPhoto from "../../../images/willach1.jpg"
import SecondPhoto from "../../../images/willach2.jpg"
import ThirdPhoto from "../../../images/willach3.jpg"

const StyledWrapper = styled.div`
    margin: 0 auto;

    img {
        display: block;
        margin: 0 auto;
        max-width: 60%;

        margin-bottom: 20px;
    }
`;

const Photos = () => (
  <StyledWrapper>
    <img src={FirstPhoto} alt="Willach cz 1" />
    <img src={SecondPhoto} alt="Willach cz 1"/>
    <img src={ThirdPhoto} alt="Willach cz 1"/>
  </StyledWrapper>
)

export default Photos
