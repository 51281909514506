import React from "react"

import styled from 'styled-components';

const StyledWrapper = styled.div`
    padding-top: 87px;

    p {
        font-size: 0.8rem;
        margin-top: 30px;
    }
`;

const Information = () => (
  <StyledWrapper>
      <p>
        Firma Infofinder jest oficjalnym dystrybutorem sprzętu firmy Willach. W ofercie posiadamy systemy do automatycznego przyjmowania i magazynowa leków w szpitalu takie jak Consis D, Consis H i Consis B oraz nie automatyczne systemy szufladowe i regałowe Fama.
      </p>
      <p>
        Urządzenia te są zintegrowane z naszym systemem HLS – FARMMED.          
      </p>
  </StyledWrapper>
)

export default Information
